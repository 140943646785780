.container {
  height: auto;
  width: 100%;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  padding-top: 0;
  color: #fff;

  .reviewsSlider {
    width: 80%;
    height: 100%;

    .sliderItem {
      width: 50%;
      height: 50%;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .headerText {
      color: #FA4141;
      text-transform: uppercase;
      margin: 8px;
      font-size: 28px;
      font-weight: bold;
    }
  
    .year {
      text-align: center;
      margin: 0;
    }
  }

  .title {
    display: flex;
    font-weight: bold;
    height: fit-content;
    justify-content: center;
    
    @media only screen and (max-width: 640px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .bigNumber {
      font-size: 92px;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: bold;
      line-height: 66px;
      text-align: center;
      color: #fff;
      margin: 0;
      font-family: aviner-next-bold;

      
      @media only screen and (max-width: 1200px) {
        margin-bottom: 24px;
      }
    }

    .titleText {
      align-self: center;
      margin-left: 8px;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      color: #fff;
      margin: 0;
      margin-left: 12px;
      font-family: aviner-next-bold;
      
      p {        
        @media only screen and (max-width: 1200px) {
          white-space: nowrap;
          font-size: 20px;
          margin-top: 0;
          margin-bottom: 8px;
        }
      }
    }
  }


  .updateBlock {
    height: fit-content;
    width: 100%;
    margin-top: 42px;

    .reviewsImage {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 36px;

      @media only screen and (max-width: 1200px) {
        flex-direction: column;
      }
  
      .phone {
        display: flex;
        width: 50%;
        justify-content: flex-end;

        img {
          width: 70%;
          height: 100%;
        }

        @media only screen and (max-width: 1200px) {
          display: none;
        }
      }

      .phoneMobile {
        display: none;

        @media only screen and (max-width: 1200px) {
          display: flex;
          justify-content: center;
        }
      }
  
      .messages {
        display: flex;
        width: 50%;
        flex-direction: column;

        @media only screen and (max-width: 1200px) {
          width: 100%;
        }
  
        .message {
          display: flex;
          flex-direction: row;
          margin: 24px;

          @media only screen and (max-width: 1200px) {
            margin: 0;
            margin-top: 24px;
            margin-bottom: 24px;
            width: 90%;
          }
  
          &.first {
            align-self: flex-start;
            @media only screen and (max-width: 1200px) {
              align-self: center;
              justify-content: center;
              margin-top: 0;
            }
          }
  
          &.second {
            align-self: flex-end;
            margin-right: 100px;

            @media only screen and (max-width: 1200px) {
              align-self: center;
              justify-content: center;
              margin-right: 0;
            }
          }
  
          &.third {
            align-self: flex-start;

            @media only screen and (max-width: 1200px) {
              align-self: center;
              justify-content: center;
            }
          }
  
          .reviewer {
            align-self: flex-end;
          }

          .review {
            @media only screen and (max-width: 1200px) {
              padding: 6px;

            }
          }
        }
      }
    }
  }
  .footerTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    text-align: center;
    margin-bottom: 24px;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: #fff;
    font-family: aviner-next-bold;

    @media only screen and (max-width: 1200px) {
      font-size: 20px;
      margin-top: 0;
      margin-bottom: 8px;
    }

    span {
      margin-top: 8px;
      margin-bottom: 8px;

      &.zeroMarginBottom {
        margin-bottom: 0;
      }

      &.zeroMarginTop {
        margin-top: 0;
      }

      .bold {
        font-weight: bold;
      }
    }
  }
}