.header {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background: linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0)) no-repeat;
  background-position: center;
  background-size: cover;
  padding: 48px;
  padding-bottom: 0;

  @media only screen and (max-width: 1200px) {
    min-height: 800px;
    height: auto;
    flex-direction: column-reverse;
    margin-bottom: -1px;
    padding: 0px;
  }

  .bold {
    margin: 0;
    display: inline-block;
    font-weight: 700;
  }

  .leftBlock {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1200px) {
      width: 100%;
      margin-top: 48px;
      padding-left: 30px;
      padding-right: 30px;
    }

    .headerCenter {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        align-self: center;
        width: 73px;
        height: 80px;
        margin-bottom: 17px;
      }

      .logoTitle {
        font-weight: bold;
        font-size: 60px;
        line-height: 82px;
        color: #fff;
        margin: 0;
        font-family: aviner-next-bold;
        text-align: center;
      }

      .update {
        color: #FA4141;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        margin: 8px;
      }
      .year {
        color: #fff;
        margin: 0;
      }

      .logoSubtitle {
        overflow-wrap: normal;
        font-style: normal;
        font-weight: normal;
        font-size: 29.0332px;
        line-height: 40px;
        min-width: 289px;
        color: #fff;
        margin: 0;
        text-align: center;

        @media only screen and (max-width: 1200px) {
          font-size: 26px;
        }
      }

      .leftLine {
        margin-top: 23px;
        align-self: flex-start;
        padding: 5px;
        border-left: 0.896915px solid #fff;

        .text {
          font-style: normal;
          font-weight: 500;
          font-size: 20.5866px;
          line-height: 28px;
          color: #fff;
          margin: 0;
          margin-top: 14px;
          margin-bottom: 14px;
          margin-left: 13px;
        }
      }

      .infoHeaderText {
        margin-top: 53px;
        margin-bottom: 0px;
        font-size: 21px;
        font-size: bold;
        color: #fff;
      }
    }
  }

  .rightBlock {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;

    @media only screen and (max-width: 1200px) {
      width: 100%;
      padding-top: 12px;
    }

    .video {
      margin: auto;
      padding-bottom: 0px;
      width: 100%;

      iframe {
        width: 100%;
        height: 460px;
        padding: 20px;
        
        @media only screen and (max-width: 1200px) {
          height: 230px;
        }
      }
    }

    img {
      position: absolute;
      margin-bottom: 10%;
      height: 110%;
    }
  }
}