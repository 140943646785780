.pricingBlock {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
  padding-bottom: 48px;

  .pricingCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .titleTextLayout {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 50px;

      p {
        font-style: normal;
        font-size: 42.4107px;
        line-height: 58px;
        text-align: center;
        margin: 0;
        color: #fff;

        @media only screen and (max-width: 1200px) {
          text-align: center;
          font-size: 28px;
        }
      }
    }

    .pricesInfo {
      display: flex;
      flex-direction: row;

      @media only screen and (max-width: 1200px) {
        flex-direction: column;
      }

      .pricingBoxes {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 1050px;
        margin-top: 64px;

        @media only screen and (max-width: 1200px) {
          height: auto;
        }
  
        .pricingBox {
          display: flex;
          flex-direction: column;
          width: 290px;
          height: fit-content;
          margin-right: 15px;
          margin-left: 15px;
          padding-left: 15px;
          align-items: flex-start;
  
          .road {
            text-align: center;
            font-style: normal;
            font-weight: bold;
            width: 90%;
            font-size: 38px;
            line-height: 52px;
            color: #fff;
            margin: 0;
            font-family: aviner-next-bold;
            margin-bottom: 35px;
            margin-right: 15px;
            margin-left: 15px;
            padding-left: 15px;
            padding-right: 15px;

            @media only screen and (max-width: 1200px) {
              margin-left: 0;
              margin-right: 0;
              padding: 0;
            }

            &.red {
              position: relative;
              color: #ff3d00;

              .fire{
                font-size: 24px;
                position: absolute;
                top: -15px;
                right: 65px;            
              }
            }
          }
  
          .item {
            font-style: normal;
            font-weight: 100;
            font-size: 17px;
            line-height: 28px;
            color: #fff;
            margin: 0;
            margin-bottom: 10px;

            &.relative {
              position: relative;
              width: 110%;
            }
          }
        }

        .pricingBox1 {
          position: absolute;
          display: flex;
          align-self: center;
          flex-direction: column;
          width: 290px;
          height: fit-content;
          justify-self: flex-end;
          margin-right: 15px;
          margin-left: 15px;
          padding-left: 15px;
          padding-right: 15px;
          bottom: 0;

          @media only screen and (max-width: 1200px) {
            position: relative;
          }

          .priceBoxW {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            align-self: center;
            flex-grow: 1;
            margin-top: 40px;
    
            .price {
              text-align: center;
              font-style: normal;
              font-weight: bold;
              font-size: 40px;
              line-height: 33px;
              margin: 0;
              color: #fff;
              margin-top: 15px;
              margin-bottom: 16px;
              font-family: aviner-next-bold;
            }
    
            .lastPrice {
              position: relative;
              align-self: center;
              font-size: 22px;
              color: #787878;
              margin:0px;
    
              &::before {
                content: "";
                display:block;
                position:absolute;
                left:0;top:50%;
                color:red;
                width:100%;
                height:0;
                border-bottom: 3px solid red;
                transform:rotate(-1deg);
              }
            }
          }
        }

      }
    }

    .proBlockInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin-top: 20px;

      @media only screen and (max-width: 1200px)  {
        display: none;
      }
    }

    .proBlockInfoMobile {
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin-top: 20px;

      @media only screen and (max-width: 1200px) {
        display: flex;
      }
    }

    .btnWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin-top: 32px;
    }
  }
}

.boldRed {
  color: #ff3d00;
  font-family: aviner-next-bold;
}

.bold {
  font-family: aviner-next-bold;
}

.warn {
  color: #F53F3F;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  margin: 0;
  height: auto;
}