.notFound {
  display: flex;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color:#fff;
  background: #14171B;

  p {
    margin-top: 10px;
  }

  a {
    font-size: 18px;
    text-decoration: none;

    &:visited {
      color:#fff;
    }
  }

  img {
    align-self: center;
    width: 53px;
    height: 60px;
  }
}