.dropItem {
  display: flex;
  outline: none;
  flex-direction: column;
  width: 100%;
  height: auto;
  cursor: pointer;
  margin-top: 55px;
  margin-bottom: 55px;

  .dropItemTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .dropItemTitleText {
      color: black;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 48px;
      margin: 0;
    }

    .icon {
      height: 10px;
      font-style: normal;
      margin: 0;
      padding: 0;
      font-weight: 900;
      font-size: 32px;
      line-height: 30px;
      text-align: center;
      margin-left: 28px;
      margin-bottom: 20px;
      color: #000000;
    }
  }

  .dropItemSubtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #858585;
    margin: 0;
    margin-top: 6px;
  }

  .dropItemBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 12px;

    &:active {
      outline: none;
      box-shadow: none;
    }
  
    &:focus {
      outline: none;
      box-shadow: none;
    }

    .dropItemText {
      color: black;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      margin: 0;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}