.aboutBlock {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
  padding-top: 89px;
  padding-bottom: 89px;

  @media only screen and (max-width: 1200px) {
    height: auto;
    width: 100%;
  }

  .centerBlock {
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 1200px) {
      width: 89%;
    }

    .aboutImage {
      display: flex;
      flex-direction: row;
      margin-bottom: 50px;

      @media only screen and (max-width: 1200px) {
        flex-direction: column;
        width: 100%;
      }

      .about {
        display: flex;
        flex-direction: column;

        p {
          margin: 0;
          color: #fff;

          @media only screen and (max-width: 1200px) {
            font-size: 36px;
            text-align: center;
          }
        }

        .author {
          font-style: normal;
          font-weight: bold;
          font-size: 36.0902px;
          line-height: 49px;
          margin-bottom: 10px;
          font-family: aviner-next-bold;

          @media only screen and (max-width: 1200px) {
            text-align: start;
          }
        }

        .name {
          font-style: normal;
          font-weight: bold;
          font-size: 61.1324px;
          line-height: 84px;
          margin-bottom: 20px;
          font-family: aviner-next-bold;

          @media only screen and (max-width: 1200px) {
            text-align: start;
          }
        }

        .desc {
          width: 530px;
          font-style: normal;
          font-weight: normal;
          font-size: 25.2447px;
          line-height: 34px;
          margin-top: 20px;
          padding-bottom: 15px;

          @media only screen and (max-width: 1200px) {
            width: 95%;
            text-align: start;
          }

          b {
            font-family: aviner-next-bold;
          }
        }
      }

      .image {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        margin-left: 80px;
        margin-top: 95px;

        @media only screen and (max-width: 1200px) {
          margin: 0;
          margin-right: 11%;
          justify-content: center;
          margin-top: 50px;
          align-self: center;
        }

        img {
          width: 396px;
          height: 396px;

          @media only screen and (max-width: 1200px) {
            width: 280px;
            height: 280px;
            align-self: center;
          }
        }
      }
    }

    .follow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      @media only screen and (max-width: 1200px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0;
      }

      .char {
        display: flex;
        flex-direction: column;
        margin-right: 80px;

        @media only screen and (max-width: 1200px) {
          justify-content: center;
          align-items: center;
        }
      }

      .num {
        font-family: aviner-next-heavy;
        font-style: normal;
        font-weight: 800;
        font-size: 75.2948px;
        line-height: 103px;
        margin: 0;
        color: #fff;
      }

      .text {
        font-style: normal;
        font-weight: normal;
        font-size: 29.3796px;
        line-height: 40px;
        margin: 0;
        color: #fff;
        margin-top: 10px;

        @media only screen and (max-width: 1200px) {
          text-align: center;
        }
      }
    }
  }
}