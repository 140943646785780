.forWhomBlock {
  width: 100%;
  height: auto;
  background: #14171B;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 44px;
  padding-bottom: 83px;
 
  @media only screen and (max-width: 1200px) {
    padding-bottom: 0px;
  }

  .forWhomCenter {
    display: flex;
    flex-direction: column;

    hr {
      width: 100%;
      margin: 0;
      margin-top: 50px;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    .forWhomTop {
      display: flex;
      flex-direction: column;
      height: 40%;

      .titleTextLayout {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        p {
          font-style: normal;
          font-weight: bold;
          font-size: 42.4107px;
          line-height: 58px;
          text-align: center;
          margin: 0;
          color: #fff;
          font-family: aviner-next-bold;

          @media only screen and (max-width: 1200px) {
            font-size: 24px;
            margin: 0;
          }
        }
      }

      .treeBox {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        margin-top: 60px;

        @media only screen and (max-width: 1200px) {
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          margin: 0;
        }

        .box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding: 20px;
          margin-left: 15px;
          margin-right: 15px;

          @media only screen and (max-width: 1200px) {
            margin: 0;
            align-items: center;
          }

          svg {
            align-self: left;
            width: 150px;
            height: 150px;
            margin-left: -24px;
            
            @media only screen and (max-width: 1200px) {
              margin-left: 0;
            }
          }

          p {
            margin: 0;
            width: 300px;
            font-style: normal;
            font-weight: 500;
            font-size: 18.3579px;
            line-height: 25px;
            color: #fff;
            margin-top: 10px;

            @media only screen and (max-width: 1200px) {
              text-align: center;
              margin-bottom: 50px;
            }

            b {
              font-family: aviner-next-bold;
            }
          }
        }
      }
    }

    .forWhomBottom {
      display: flex;
      flex-direction: column;
      height: 40%;
      margin-bottom: 48px;
      margin-top: 48px;

      @media only screen and (max-width: 1200px) {
        margin-bottom: 50px;
      }

      .titleTextLayout {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        p {
          font-style: normal;
          font-weight: bold;
          font-size: 42.4107px;
          line-height: 58px;
          text-align: center;
          margin: 0;
          color: #fff;
          font-family: aviner-next-bold;

          @media only screen and (max-width: 1200px) {
            font-size: 36px;
            margin: 0;
          }
        }
      }

      .treeBoxLeft {
        display: flex;
        flex-direction: row;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        justify-content: center;
        margin-top: 60px;

        @media only screen and (max-width: 1200px) {
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          margin: 0;
          margin-top: 60px;
        }

        .box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding: 20px;
          margin-left: 15px;
          margin-right: 15px;

          @media only screen and (max-width: 1200px) {
            margin: 0;
            align-items: center;
          }

          .muteVideo {
            video {
              margin-top: 0;

              @media only screen and (max-width: 1200px) {
                width: 300px;
              }
            }
          }

          p {
            margin: 0;
            width: 352px;
            font-style: normal;
            font-weight: 500;
            font-size: 18.3579px;
            line-height: 25px;
            color: #fff;
            margin-top: 10px;

            b {
              font-family: aviner-next-bold;
            }
          }

          .number {
            font-size: 100px;
            font-family: aviner-next-heavy;
            margin: 0;
            margin-bottom: 40px;

            @media only screen and (max-width: 1200px) {
              justify-content: center;
              text-align: center;
            }
          }
        }
      }

      .treeBoxRight {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        margin-top: 60px;
        margin-right: 60px;

        @media only screen and (max-width: 1200px) {
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          margin: 0;
          margin-top: 60px;
        }

        .boxRightMobile {
          visibility: hidden;
          height: 0;
          display: none;

          @media only screen and (max-width: 1200px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            margin: 0;
            visibility: visible;
            height: auto;
          }

          .number {
            display: flex;
            justify-content: flex-start;
            font-size: 100px;
            font-family: aviner-next-heavy;
            margin: 0;
            margin-bottom: 40px;

            @media only screen and (max-width: 1200px) {
              justify-content: center;
              text-align: center;
            }
          }

          p {
            margin: 0;
            width: 352px;
            font-style: normal;
            font-weight: 500;
            font-size: 18.3579px;
            line-height: 25px;
            color: #fff;
            margin-top: 10px;

            b {
              font-family: aviner-next-bold;
            }
          }
        }

        .boxRight {
          display: flex;
          flex-direction: column;
          align-items: center;
          align-self: center;
          padding: 20px;
          margin-left: 15px;
          margin-right: 15px;
          visibility: visible;
          height: 215px;

          @media only screen and (max-width: 1200px) {
            visibility: hidden;
            display: none;
            height: 0px;
            padding: 0px;
          }
        }

        .box {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          padding: 20px;
          margin-left: 15px;
          margin-right: 15px;

          &.boxRight {
            @media only screen and (max-width: 1200px) {
              visibility: hidden;
              display: none;
              height: 0px;
              padding: 0px;
            }
          }

          @media only screen and (max-width: 1200px) {
            margin: 0;
            align-items: center;
          }

          .muteVideo {
            video {
              @media only screen and (max-width: 1200px) {
                margin-top: 0;
                width: 300px;
              }
            }

            &.first {
              video {
                margin-top: -20px;
              }
            }

            &.second {
              video {
                margin-top: 20px;
              }
            }

            &.third {
              video {
                margin-top: 44px;
              }
            }
          }
          p {
            margin: 0;
            width: 352px;
            font-style: normal;
            font-weight: 500;
            font-size: 18.3579px;
            line-height: 25px;
            color: #fff;
            margin-top: 10px;

            b {
              font-family: aviner-next-bold;
            }
          }

          .number {
            display: flex;
            justify-content: flex-start;
            font-size: 100px;
            font-family: aviner-next-heavy;
            margin: 0;
            margin-bottom: 40px;

            @media only screen and (max-width: 1200px) {
              justify-content: center;
              text-align: center;
            }
          }
        }
      }
    }

    .btnWrapper {
      align-self: center;
      margin-top: 53px;

      @media only screen and (max-width: 1200px) {
        margin-bottom: 48px;
      }
    }

    .musicBlockTitle {
      text-align: center;
      font-size: 32px;
      color: #fff;
      font-family: aviner-next-bold;
    }

    .centerText {
      color: white;
      padding-top: 24px;
      width: 500px;
      font-style: italic;
      flex-direction: column;
      align-self: center;

      @media only screen and (max-width: 800px) {
        color: white;
        width: 80%;
        margin-top: 0px;
        padding-top: 0px;
        text-align: center;
        font-style: italic;
        flex-direction: column;
        align-self: center;
      }

      b {
        font-family: aviner-next-bold;
      }
    }
  }
}