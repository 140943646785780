.faqBlock {
  background: #f3f3f3;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;

  .centerBlock {
    width: 70%;
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
    margin-top: 62px;
    margin-bottom: 24px;

    .faqTitle {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 64px;

      p {
        font-style: normal;
        font-weight: bold;
        font-size: 48.5405px;
        line-height: 66px;
        text-align: center;
        color: black;
        margin: 0;
        font-family: aviner-next-bold;
      }
    }

    .dropMenu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
    }
  }
}