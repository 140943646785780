.dropItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  cursor: pointer;

  .dropItemTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 41px;
      color: black;
      margin: 0;
    }

    .icon {
      height: 10px;
      font-style: normal;
      margin: 0;
      padding: 0;
      font-weight: 900;
      font-size: 32px;
      line-height: 30px;
      text-align: center;
      margin-left: 28px;
      color: #000000;
      margin-bottom: 26px;
    }
  }

  .dropItemBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 12px;

    .dropItemText {
      color: black;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}