* {
  font-family: aviner-next;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  background-color: #ffffff;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

#root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: aviner-next;
  src: url("fonts/AvenirNextCyr-Regular.ttf");
  padding: 0;
}

@font-face {
  font-family: aviner-next-bold;
  src: url("fonts/AvenirNextCyr-Bold.ttf");
  padding: 0;
}

@font-face {
  font-family: aviner-next-heavy;
  src: url("fonts/AvenirNextCyr-Heavy.ttf");
  padding: 0;
}

.player_list {
  box-shadow: 0px 4px 20px rgba(65, 83, 129, 0.25);
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  background: #ffffff;

  &.dark {
    box-shadow: 0px 4px 20px rgb(214, 214, 214);
  }
  
  @media (min-width: 320px) {
    padding: 35px;
    margin-bottom: 67px;
    justify-content: center;
  }

  @media (min-width: 768px) {
      justify-content: space-around;
      padding: 40px;
  }

  .audio-player { 
    @media (min-width: 320px) {
      width: 100%;
      margin-top: 0;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    @media (min-width: 768px) {
        width: 47.5%;
    }

    @media (min-width: 960px) {
        width: 33%;
        margin-bottom: 40px;
        margin-top: 40px;
    }
  }
}
