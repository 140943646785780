.chapterBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: black;
  height: auto;

  .chapterOne {
    display: flex;
    flex-direction: row;
    width: 100%;

    @media only screen and (max-width: 1200px) {
      flex-direction: column;
    }  

    .leftChapterBlock {
      display: flex;
      width: 50%;
      height: 100%;
      justify-content: flex-end;
      align-items: center;

      @media only screen and (max-width: 1200px) {
        justify-content: center;
        width: 100%;
      } 

      .leftChapterCenter {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @media only screen and (max-width: 1200px) {
          width: 100%;
          display: grid;
          grid-template-columns: 0.15fr 1fr 0.1fr;
          grid-template-rows: auto 1fr;
        }

        .chapterLink {
          margin-bottom: 50px;
          margin-top: 50px;
          padding-top: 4px;
          padding-bottom: 4px;
          padding-right: 40px;
          padding-left: 40px;
          background: linear-gradient(180deg, #FA4141 0%, #D13333 100%);
          grid-area: 1 / 2 / 1 / 2;

          @media only screen and (max-width: 1200px) {
            align-self: flex-start;
            margin-bottom: 25px;
            width: fit-content;
          }

          p {
            color: #fff;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 49px;
            margin: 0;
            font-family: aviner-next-bold;
            
            @media only screen and (max-width: 1200px) {
              font-size: 20px;
            }
          }
        }

        .descriptionBlock {
          display: flex;
          flex-direction: column;
          align-items: center;
          grid-area: 2 / 2 / 2 / 2;

          .chapterDescription {
            margin: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            color: #fff;
            
            @media only screen and (max-width: 1200px) {
              width: 100%;
            }

            b {
              font-family: aviner-next-bold;
            }
          }
        }
      }
    }

    .rightChapterBlock {
      display: flex;
      width: 50%;
      height: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 1200px) {
        width: 100%;
        height: 300px;
      }

      .rightChapterCenter {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 200px;
        margin-top: 100px;
        width: 550px;
        height: 500px;

        @media only screen and (max-width: 1200px) {
          margin-right: 0;
          margin-top: 0;
          flex-direction: column;
          height: 400px;
        }

        .slide1, .slide2 {
          position: absolute;
        }

        .slide1 {
          z-index: 1;
          width: 550px;
          margin-left: 110px;
          margin-bottom: 75px;
          margin-top: 100px;
          
          @media only screen and (max-width: 1200px) {
            width: 330px;
            margin-left: 0px;
            margin-bottom: 0;
            margin-top: 0;
          }
        }
      }
    }
  }

  .chapterOneBottom {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    padding-bottom: 30px;

    .bottomChapterBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 60%;

      @media only screen and (max-width: 1200px) {
        width: 100%;
      }

      .chapterDescription {
        margin: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: #fff;

        @media only screen and (max-width: 1200px) {
          width: 70%;
        }

        b {
          font-family: aviner-next-bold;
        }
      }

      .chapterDescriptionLast {
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: #fff;
        align-self: flex-start;
        font-style: italic;

        @media only screen and (max-width: 1200px) {
          margin-left: 12px;
        }
      }
    }

    .bottomChapterBlock1 {
      width: 40%;
    }
  }
}