.container {
  display: flex;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 32px;
  color:#fff;
  background: #14171B;

  .title {
    width: 90%;
  }

  .details {
    width: 70%;
    font-size: 18px;
    margin-top: 36px;
    margin-bottom: 48px;
  }

  .signUpBtn {
    text-decoration: none;
    color: #fff;
    background: #1e96c8;
    padding: 8px 48px;
    font-weight: 500;
    font-size: 18px;
    border-radius: 16px;
    vertical-align: middle;
    text-align: center;
    border: 0;
    position: relative;

    &:hover {
      cursor: pointer;
      background-color: #047eb3;
    }
  }
}