.footer {
  width: 100%;
  height: 232px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #14171B;

  .mediaLinkBlock {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    i {
      font-style: normal;
      font-weight: normal;
      font-size: 29.5652px;
      line-height: 34px;
      text-align: center;
      color: #FFFFFF;
      margin-right: 15px;
    }
  }

  .contactBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 19px;

    p {
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 17.2657px;
      line-height: 24px;
      color: #E5E5E5;

      b {
        font-family: aviner-next-bold;
      }
    }

    a {
      text-decoration: none;
      font-family: aviner-next;
      color: #fff;
      margin-top: 10px;
    }
  }

  .footerLogoBlock {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 18px;

    img {
      width: 35px;
    }

    p {
      color: #fff;
      font-style: normal;
      font-weight: normal;
      font-size: 22.9682px;
      line-height: 31px;
      margin: 0;
      margin-left: 22px;
    }
  }
}