.container {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  label {
    color: #fff;
    font-size: 12px;
    margin: 0;
    margin-bottom: 2px;
  }
  
  .numberFormat {
    padding: 8px 12px;
    width: 100%;
    outline: none;
    font-size: 17px;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 4px;
    color: #fff;

    &:focus {
      border-color: #33b8ff;

      & + label {
        color: #33b8ff;
      }
    }
  }
}
