.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: black;

  .header {
    font-size: 28px;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 32px;

    @media only screen and (max-width: 1200px) {
      font-size: 18px;
      text-align: center;
    }
  }

  .bodyWithImages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;

    .infoTitle {
      font-size: 20px;
      font-weight: bold;
      width: 50%;
      text-align: center;
      align-self: center;

      @media only screen and (max-width: 1200px) {
        font-size: 16px;
      }
    }

    .images {
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      @media only screen and (max-width: 1200px) {
        flex-direction: column;
      }

      .imageBlock {
        display: flex;
        flex-direction: column;
        margin-left: 36px;
        margin-right: 36px;
        align-items: center;

        @media only screen and (max-width: 1200px) {
          margin-top: 24px;
          margin-bottom: 24px;
        }
      
        &.smaller {
          align-self: flex-end;
          margin-right: 0px;

          @media only screen and (max-width: 1200px) {
            margin-right: 36px;
          }
        }

        .imageLabel {
          font-family: aviner-next-bold;
          text-align: center;
          font-size: 20px;
          margin-top: 16px;
        }
      }
    }
  }

  .textBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 44px;

    span {
      font-size: 23px;
      width: 50%;
      text-align: center;
      margin-top: 16px;

      @media only screen and (max-width: 1200px) {
        font-size: 16px;
        margin-top: 0px;
        width: 60%;

        &:first-child {
          margin-bottom: 24px;
        }
      }
    }
  }



  .packsBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 44px;

    .packsTitle {
      font-size: 28px;

      @media only screen and (max-width: 1200px) {
        font-size: 18px;
        text-align: center;
      }
    }

    .packBlockInfo {
      display: flex;
      flex-direction: column;
      width: 70%;

      @media only screen and (max-width: 1200px) {
        width: 100%;
      }

      .packBlockInfoTitle {
        text-align: center;
        font-size: 48px;
        font-family: aviner-next-bold;
        margin-top: 44px;

        &.premium {
          margin-top: 0;
        }
      }

      .prevPacks {
        font-size: 16px;
        text-align: center;
        margin-bottom: 32px;
        margin-top: 32px;

        .blue {
          color: #25318D;
        }

        .red {
          color: #AA2900;
        }
      }

      .icon {
        align-self: center;
        font-size: 44px;
      }

      .packBlock {
        display: flex;
        flex-direction: row;

        @media only screen and (max-width: 1200px) {
          flex-direction: column;
          width: 100%;
          align-items: center;
        }
  
        .imagePackBlock {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 50%;
          margin: 24px;

          @media only screen and (max-width: 1200px) {
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;

            &:first-child {
              margin-top: 24px;
            }
          }
  
          .text {
            font-size: 24px;
            text-align: center;
            height: 50%;

            @media only screen and (max-width: 1200px) {
              font-size: 18px;
              position: absolute;
              left: 0;
              right: 0;
              top: 12px;
            }
          }

          img {
            align-self: center;
            width: 45%;
            height: 100%;

            @media only screen and (max-width: 1200px) {
              width: 100%;
              height: 100%;
            }
          }
        }
  
        &.right {
          flex-direction: row;

          img {
            width: 100%;
            height: 100%;
          }

          @media only screen and (max-width: 1200px) {
            flex-direction: column;
          }
        }
      }
    }

    .musicBlock {
      width: 85%;
      align-self: center;

      @media only screen and (max-width: 1200px) {
        width: 80%;
      }

      .musicBlockTitle {
        text-align: center;
        font-size: 32px;
        font-family: aviner-next-bold;

        @media only screen and (max-width: 1200px) {
          margin-top: 0;
        }
      }
    }

    .footerTitle {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      .text {
        text-align: center;
        font-size: 20px;
        width: 75%;

        @media only screen and (max-width: 1200px) {
          font-size: 16px;
        }
      }
    }
  }

  .footerBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;

    @media only screen and (max-width: 1200px) {
      width: 100%;
    }

    .textMute {
      width: 100%;
      text-align: start;
      font-size: 14px;

      @media only screen and (max-width: 1200px) {
        font-size: 11px;
        align-self: flex-start;
        margin-left: 12px;
      }
    }
  }

  .bold {
    font-family: aviner-next-bold;
  }

  .heavy {
    font-family: aviner-next-heavy;
  }
}