.courseProgramBlock {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    margin-top: -1px;
  }

  .titleBlock {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: black;
    width: 100%;
    height: 130px;
    margin-bottom: 12px;

    @media only screen and (max-width: 1200px) {
      padding-bottom: 24px;
    }

    p {
      font-style: normal;
      font-weight: bold;
      font-size: 48.5405px;
      line-height: 66px;
      text-align: center;
      color: #fff;
      margin: 0;
      font-family: aviner-next-bold;
    }
  }

  .centerBlock {
    display: flex;
    flex-direction: column;
    width: 70%;

    .chapters {
      text-align: center;
      margin-top: 50px;
      font-size: 41px;
      font-weight: bold;
      font-family: aviner-next-bold;
      align-self: center;
    }

    .dropMenuBlock {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
    }

    .footerTitle {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 24px;

      p {
        margin-bottom: 0;
      }
    }
  }
}