.termsBlock {
  background: #14171B;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color:#fff;
  flex: 1;

  .termsAndConditions {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 80%;
    border: 1px solid #FA4141;
    padding: 20px;
    border-radius: 8px;
    overflow: scroll;

    @media only screen and (max-width: 1200px) {
      width: 100%;
      border: 0;
    }

    &::-webkit-scrollbar {
      width: 0;
    }

    img {
      align-self: center;
      width: 73px;
      height: 80px;
    }

    .termsHeader {
      font-size: 32px;
      text-align: center;
    }

    .termsContent {
      p {
        margin-left: 25px;
      }
    }

    .termsFooter {
      bottom: 5px;
      padding: 20px;
      padding-bottom: 0px;
    }
  }
}