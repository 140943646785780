
.player_list {
  box-shadow: 0px 4px 20px rgba(65, 83, 129, 0.25);
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  
  @media (min-width: 320px) {
    padding: 35px;
    margin-bottom: 67px;
    justify-content: center;
  }

  @media (min-width: 768px) {
      justify-content: center;
      padding: 55px;
  }

  .audion-player {
    @media (min-width: 320px) {
      width: 100%;
      margin-top: 0;
      margin-bottom: 20px;
    }

    @media (min-width: 768px) {
        width: 47.5%;
    }

    @media (min-width: 960px) {
        width: 33%;
        margin-bottom: 40px;
    }
  }
}


.audio-player {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 40%;
  margin: 20px 0;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
  }

  &__title {
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: gray;
  }

  &__button {
    display: flex;
    align-items: center;
    width: 18px;
    margin-right: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  & .react-waves {
    padding: 0;
    margin: 0;
    &:hover {
      cursor: pointer;
    }
  }
}