.btnGo {
  background: #FA4141;
  border-radius: 50px;
  font-weight: normal;
  line-height: 39px;
  color: #fff;
  border: 2px solid #FA4141;
  transition: ease-in-out 0.25s;
  cursor: pointer;

  &.small {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 32px;
    padding-right: 32px;
  }

  &.big {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 69px;
    padding-right: 69px;
    font-size: 28px;

    @media only screen and (max-width: 1200px) {
      padding-left: 24px;
      padding-right: 24px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &.black {
    &:hover {
      background: none;
      color: black;
      border: 2px solid #FA4141;
    }
  }

  &.light {
    background: #fff;
    border-radius: 50px;
    font-weight: normal;
    line-height: 39px;
    color: #000;
    border: 2px solid #fff;
    font-weight: bold;

    &:hover {
      background: none;
      color: #fff;
      border: 2px solid #fff;
    }
  }

  &.white {
    &:hover {
      background: none;
      color: #fff;
      border: 2px solid #FA4141;
    }
  }

  &.approve {
    font-size: 18px;

    @media only screen and (max-width: 1200px) {
      font-size: 16px;
    }
  }
}