.modalContainer{
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0,0,0,0.8);

  .modalContent {
    background-color: #14171B;
    margin: 5% auto;
    border-radius: 4px;
    height: 80%;
    overflow: auto;
    width: 40%;

    box-shadow: 0px 3px 20px rgb(153, 151, 151);

    &::-webkit-scrollbar {
      width: 0;
    }

    @media only screen and (max-width: 1200px) {
      width: 90%;
      padding: 0;
    }

    .checkoutHeader {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: black;
      width: 100%;

      .close {
        position: absolute;
        color: #aaa;
        font-size: 28px;
        font-weight: bold;
        align-self: flex-end;
        right: 15px;
        top: 0;
        cursor: pointer;

        &:hover {
          color: rgb(211, 208, 208)
        }
      }

      p {
        text-align: center;
        color: #fff;
        font-size: 34px;
        margin-top: 8px;
      }

      img {
        margin-top: 34px;
      }
    }

    .checkoutForm {
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: fit-content;

      & div {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      &::-webkit-scrollbar {
        width: 0;
      }

      .fields {
        width: 50%;

        @media only screen and (max-width: 1200px) {
          width: 90%;
        }
      }
    }

    .success {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
      padding: 20px;
      color: #fff;
    }
  }
}
